.aui-post-collaboration-strip {
    max-width: 642px;
    width: 95%;
    border: 1px solid #dbe3eb;
    border-radius: 8px;
    padding: 0.7rem;
    display: flex;
    margin: 1.25rem auto;

    &-content {
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
    }

    &-image {
        min-width: 90px;
        height: 90px;
        background-color: #ff406e;
        border-radius: 12px;
        overflow: hidden;

        > img {
            width: 100%;
            height: auto;
            border-radius: inherit;
        }
    }

    &-top {
        display: flex;
        justify-content: space-between;
    }

    &-text {
        color: #33475b;
    }

    &-creator {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #65ccff;
        color: #092f42;
    }

    &-bottom {
        display: flex;
        gap: 1.5rem;
        align-items: center;
    }

    &-icon {
        width: 1.5rem;
    }

    &-time {
        color: #c2cbd0;
    }

    &-tag {
        border: 1px solid #df124b;
        background-color: #df124b;
        color: white;
        padding: 0 0.5rem;
        border-radius: 3px;
    }
}

.tooltip {
    display: none;
    visibility: hidden;
    width: auto !important;
    text-align: center !important;
    padding: 1.15rem 1.5rem;
    border-radius: 5px !important;
    align-items: center;
    justify-content: center;
    position: absolute !important;
    z-index: 100;
    &:hover {
        display: flex;
        visibility: visible;
    }
}

.top {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;

    &::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #3a4456 transparent transparent transparent;
    }
}

.bottom {
    top: 100%;
    left: 50%;
    margin-left: -60px;

    &::after {
        content: " ";
        position: absolute;
        bottom: 100%; /* At the top of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #3a4456 transparent;
    }
}

.right {
    left: 105%;

    &::after {
        content: " ";
        position: absolute;
        top: 50%;
        right: 100%; /* To the left of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #3a4456 transparent transparent;
    }
}

.left {
    right: 105%;

    &::after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 100%; /* To the right of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #3a4456;
    }
}

.aui-post-manager {
    display: flex;
    background-color: white;
    flex-direction: row !important;
    box-shadow: -6px 3px 6px rgba(142, 160, 179, 0.32);

    .post-manager-title-bar {
        background-color: white;
        width: 100%;
        min-height: 64px;
        max-height: 64px;
        display: flex;
        align-items: center;
        border: 1px solid #D6DFE8;
        border-right:none ;
        box-shadow: 0 3px 6px #33475b17;

        @media (max-width: 992px) {
            font-size: 0.85rem;
        }
    }

    &-cross {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 2rem;
        cursor: pointer;
        color: #33475b;
        transition: all 0.3s ease;
        transform: rotateZ(45deg);
        background: #dde5ec;
        margin-top: 0.5rem;
        margin-right: 0.5rem;

        &:hover {
            background-color: #33475b;
            color: #fff;
        }
    }
}

.preview-section-container {
    width: 30rem;
    position: "relative";

    @media (max-width: 1400px) {
        max-width: 420px;
    }

    @media (max-width: 700px) {
        min-width: 0;
    }
}

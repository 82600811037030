.instaPost {
    position: relative;
    margin: 12px auto;
    max-width: 500px;
    width: 90%;
    border-radius: 7px;
    background-color: white;
    border: 1px solid #D6DFE8;
    box-shadow: 0px 0px 4px rgba(200, 200, 200, 0.8);
    cursor: pointer;

    > .postTop {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // margin-bottom: 12px;

        > .icon {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
                border-radius: 50%;
                height: 100%;
                width: auto;
            }
        }

        > .title {
            margin-left: 0.6rem;
            text-align: left;

            > .name {
                font-size: 0.9rem;
                color: #092f42;
                line-height: 19px;
                font-weight: 500;
            }
        }
    }

    > .image {
        width: 100%;
        height: 425px;
        overflow: hidden;
        background-color: #292626b9;

        div {
            height: 100%;
            width: 100%;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    > .stats {
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 12px;
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .stats-1 {
            display: flex;
            .comment,
            .heart,
            .share {
                margin-right: 1rem;
                display: flex;
                cursor: pointer;
                align-items: center;
                line-height: 145%;
                > svg {
                    height: 25px;
                    width: 25px;
                    margin-right: 8px;
                    fill: rgb(35, 38, 41);
                }
            }
        }
        .stats-2 {
            margin-right: 1rem;
            .save {
                display: flex;
                cursor: pointer;
                align-items: center;
            }
        }

        > div {
            margin-right: 1rem;
        }
    }
}

.instagramCaption{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    > .text {
        padding: 12px;
        text-align: left;
        font-size: 13px;
        color: #050505;
        font-weight: 300;
        span {
            font-weight: 600;
            margin-right: 5px;
        }

        a {
            text-decoration: none;
            color: #00aeef;

            &:hover {
                text-decoration: underline;
                opacity: 0.8;
            }
        }

        > .readMore {
            color: #99acc2;
            padding: 12px 12px 0 0;
            text-align: left;
            font-weight: 500;
        }
    }
    
    >.postText {
        white-space: pre-line;
    }

    .creator{
        padding-right: 4px;
        padding-top: 0;
    }
}

.firstComment{
    display: flex;
    flex-direction: row;
    justify-content: left;

    > .text {
        padding: 12px;
        text-align: left;
        font-size: 13px;
        color: #050505;
        font-weight: 300;
        span {
            font-weight: 600;
            margin-right: 5px;
        }

        a {
            text-decoration: none;
            color: #00aeef;

            &:hover {
                text-decoration: underline;
                opacity: 0.8;
            }
        }
    }

    .creator{
        padding-right: 4px;
        padding-top: 6px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.commentLine{
    text-align: left;
    padding: 0 0 4px 12px;
}

.commentLike{
    margin-left: auto;
    width: 1rem;
    height: 1rem;
    svg{
        max-height: 1rem;
    }
}

.changeColor{
    color: rgb(0, 172, 238);
}

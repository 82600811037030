.aui-post-collaboration {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;

    &-publisher {
        padding: 1rem 5rem;
        display: flex;
        align-items: center;
        gap: 2rem;
        font-size: 1rem;

        &-wrapper {
            min-width: 240px;
            max-width: 250px;
            width: 95%;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            border: 1px solid #D6DFE8;
        }
        &-name {
            text-align: left;
            color: #33475b;
            flex: 1;
        }
    }
}
.aui-post-collaboration-chat-box {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 325px);
    border-top: 1px solid #dbe3e9a1;
    // margin-top: 2rem;
    box-shadow: inset 0 3px 6px #cdd7e391;

    #loader-container {
        display: inline-block;
        margin: 0.25rem auto;
        background-color: white;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        position: relative;
    }

    &-me {
        margin-left: auto;
        flex-direction: row-reverse;
    }

    &-me &-card {
        &::after {
            display: block;
        }

        &::before {
            display: none;
        }
    }

    &-not-me &-card {
        &::after {
            display: none;
        }

        &::before {
            display: block;
        }
    }

    &-wrapper {
        overflow-y: auto;
        flex: 1 1;
        position: relative;
        background: url("../../icons//social\ help.svg");
    }
}
.aui-post-collaboration-comment-input {
    box-shadow: 0 -3px 6px #cdd7e391;

    &-rejected {
        background-color: #ffe8e9;
        border: 1px solid #f2545b;
    }

    &-status-wrapper {
        padding: 1rem 2rem 1rem 2rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 1px solid #ecb254;
        background-color: #fef6e9;

        > input {
            border: 1px solid #cdd7e3;
            outline: none;
            border-radius: 2px;
            height: 40px;
            padding: 0.5rem 1rem;
        }

        > span {
            font-size: 0.95rem;
            font-weight: 500;

            &:nth-child(2) {
                margin-left: auto;
            }
        }

        &-button {
            border-radius: 2px;
            min-width: 102px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:nth-child(2) {
                border: 1px solid #25c16f;
                background-color: #25c16f;
                color: white;
                margin-left: auto;
            }
            &:last-child {
                border: 1px solid #f2545b;
                background-color: white;
                color: #f2545b;
            }
        }

        &-rejected {
            border-color: transparent;
            background-color: #ffe8e9;
        }
    }

    &-wrapper {
        margin: 1rem 2rem 1rem 2rem;
        height: 106px;
        background-color: white;
        border-radius: 9px;
        border: 1px solid #cdd7e3;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0.5rem;
        gap: 0.5rem;

        textarea {
            flex: 1;
            border: none;
            outline: none;
            height: 100%;
            width: 100%;
            background-color: transparent;
        }
    }

    &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        min-width: 91px;
        height: 32px;
        border-radius: 4px;
        background-color: #3daef9;
        color: white;
        margin-left: auto;

        &-disabled {
            cursor: not-allowed;
            background-color: #ccd6dd;
            color: #657786;
        }
    }
}

.error {
    position: fixed;
    top: 0;
    transition: all 0.3s ease;
    width: 100%;
    z-index: 10000000000000004;

    > .modal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000001;
        background-color: rgba(0, 0, 0, 0.4);
        overflow: hidden !important;
    }

    > .msg {
        position: fixed;
        display: flex;
        top: 0;
        left: 49.5vw;
        transform: translateX(-50%);
        margin-top: 1rem;
        width: 30rem;
        max-width: 85vw;
        border-width: 1px;
        border-radius: 2px;
        padding: 1rem 1rem;
        border-style: solid;
        color: #393c3d;
        font-weight: 500;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
        z-index: 1000001 !important;

        > div {
            text-align: right !important;
            width: 7.5%;

            > span {
                color: #393c3d;
                &:hover {
                    cursor: pointer;
                    transform: scale(1.05);
                }
            }
        }

        > section {
            display: flex;
            width: 92.5%;
            align-items: center;
            justify-content: flex-start;
            text-align: left;

            > img {
                max-height: 1.6rem;
                height: 100%;
                width: auto;
                margin-right: 1rem;
            }
            > div {
                font-size: 0.875rem;
                line-height: 1.43;
                color: #393c3d;
                letter-spacing: 0.01071em;
            }
        }
    }

    > .danger {
        border-color: #ffb5c8;
        background-color: #ffe3ea;
        border-radius: 0px;
        color: #393c3d;
    }
    > .success {
        border-color: #67d4e3;
        background-color: #dffeff;
        border-radius: 0px;
        color: #393c3d;
    }
    > .warn {
        border-color: #f8b700;
        background-color: #fff4e4;
        border-radius: 0px;
        color: #393c3d;
    }
    > .info {
        border-color: #212121;
        background-color: #212121;
    }
}

.slideUp {
    animation: up 0.5s ease-out 1;
    transform-style: preserve-3d;
}

.slideDown {
    animation: down 0.5s ease-in 1;
    transform-style: preserve-3d;
}

@keyframes up {
    0% {
        transform: translate(-50%, 0%);
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -125%);
    }
}

@keyframes down {
    0% {
        transform: translate(-50%, -125%);
        opacity: 0;
    }
    85% {
        opacity: 1;
    }
    100% {
        transform: translate(-50%, 0%);
    }
}

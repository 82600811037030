.video-container {
    --light-color: 255, 255, 255;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
    .video {
        height: 100%;
        width: 100%;
        position: relative;
        max-width: calc(100vw - 2.5rem);
        overflow: hidden;

        video {
            width: 100%;
            height: 100%;
            // object-fit: contain;
            max-height: 565px;
        }

        .video-actions {
            position: absolute;
            inset: 0;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            .play-pause {
                margin-inline: auto;
                padding-left: 4rem;
            }

            .volume {
                align-self: flex-start;
            }

            button {
                border-radius: 50%;
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(var(--light-color));
                border: none;
                transition: 0.15s;

                &:hover {
                    scale: 1.05;
                }

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }
}

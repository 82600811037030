.aui-help-header {
    padding: 1rem 20px;
    box-shadow: 0px 3px 6px #425b7638;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8%;

    > p {
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0.8px;
    }

    > div {
        padding: 8px;
        transition: all 0.1s ease-in-out;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(50, 50, 50, 0.75);

        > img {
            width: 16px;
            height: 16px;
        }
        &:hover {
            background-color: rgba(50, 50, 50, 1);
        }
    }
}

.aui-new-post-manager-bottom-bar {
    min-height: 60px;
    border-top: 1px solid #D6DFE8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    gap: 1rem;

    @media (max-width: 550px) {
        flex-direction: column;
        height: 150px;
        padding: 8px 0;
        gap: 0.2rem;
    }

    &-button {
        padding: 0;
        max-width: 40%;
        height: 2.1rem;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        max-width: 8.5rem;
        cursor: pointer;
        position: relative;
        font-size: 0.7rem;

        @media (max-width: 550px) {
            width: 100%;
            max-width: 95%;
        }

        &-inactive {
            color: darkgray !important;
            background-color: rgba(169, 169, 169, 0.25) !important;
            color: darkgray !important;
            cursor: not-allowed;
        }
    }

    &-custom-menu {
        min-width: 200px;

        .aui-searchable-menu-selected-value {
            padding-right: 0.7rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100%);

            @media screen and (max-width: 550px) {
                justify-content: center;
            }
        }

        @media (max-width: 1350px) {
            min-width: 160px;
        }

        @media (max-width: 1020px) {
            min-width: 130px;
        }

        @media screen and (max-width: 550px) {
            width: 95%;
            min-width: 95%;
        }
    }
    &-custom-menu * {
        font-size: 0.7rem;
        text-align: left;
        min-width: 100%;
    }

    &-container {
        // width: fit-content;
        position: relative;
        min-width: 98px;
        @media (max-width: 550px) {
            background-color: #6a78d1;
            padding: 0 !important;
        }
    }

    &-draft {
        color: #33475b;
        background-color: #dde6ef;
        position: absolute;
        width: calc(100%);
        padding: 0;
        border: 1px solid #D6DFE8;
        background-color: white;
        border-radius: 4px;
        left: 0;
        // font-size: smaller;
        text-align: center;
        display: block;
        top: -2px;
        transform: translateY(-100%);
        height: auto;
    }

    &-date {
        // border: 1px solid #DFE3EB;
        background-color: white;
        color: #a5b6c9;
        width: 70%;
        max-width: 30rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 550px) {
            order: -1;
            margin-top: 5px;
            height: 60px;
            max-width: 100%;
            width: 100%;
        }

        // input,
        // .date {
        //     border: none;
        //     outline: none;

        // }
        

        .date {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 100%;
            .date-picker-component-wrapper {
                z-index: 20;
                > div:first-child {
                    border: 1px solid #a5b6c9;
                    border-radius: 5px;

                    button {
                        svg {
                            height: unset;
                        }
                    }

                    > div:first-child {

                        div:first-child {
                            border: none !important;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        > div:first-child {
                            svg {
                                height: 2rem;
                            }
                        }

                        div {
                            svg {
                                height: unset;
                            }
                        }

                        input {
                            padding: 0 4px 0 5px;
                            height: 2rem;
                            border: none !important;
                            font-size: 0.7rem;
                            min-width: 110px !important;
                        }
                    }

                    div:nth-child(2){
                        div:first-child{
                            border-top-color: #D6DFE8;
                        }
                    }
                }
            }
        }

        .time-picker {
            height: 2.1rem;

            > div {
                height: 100%;
                border: none;
                outline: none;
                border: 1px solid #a5b6c9;
                border-radius: 5px !important;
                justify-content: space-between;
                padding: 0;
                max-width: 150px;

                input {
                    background-color: white;
                    font-size: 0.7rem;
                    outline: none;
                    border: none;
                }

                > div:first-child {
                    height: 100%;
                    width: 29px;
                    background-color: #dde5ec;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
                > div:nth-child(7) {
                    padding: 0 4px;
                    margin-left: 0 !important;
                }
            }
        }
        
    }
    &-queue  {
            
        >.queue {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            outline: none;
            border: 1px solid #a5b6c9;
            border-radius: 5px !important;
            justify-content: flex-start;
            padding: 10px;
            max-width: 150px;
            white-space: nowrap;
            font-size: 0.7rem;
            letter-spacing: 1px;
            path{
                fill: #425A75;
            }
            @media (max-width: 800px) {
                >span{
                    display: none;
                }
            }

        }
    
    }

    &-post {
        background-color: #6a78d1;
        color: white;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        padding: 0 0.5rem;
        width: 100%;
    }

    &-post:hover {
        background-color: #606cbb;
    }
}

.rc-time-picker-panel {
    z-index: 10000000001;
}
.rc-time-picker {
    margin-right: 1rem;
}

.dropdown-icon {
    background-color: #6a78d1;
    height: 100%;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid rgba(52, 52, 55, 0.28);
}
.dropdown-icon:hover {
    background-color: #606cbb;
}
.aui-new-post-manager-bottom-bar-custom-menu * {
    max-width: 100%;
}
.aui-custom-menu-input {
    overflow: hidden;

    @media screen and (max-width: 550px) {
        width: 100vw;
    }
}
.aui-custom-menu-input span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    padding-right: 15px;

    @media screen and (max-width: 550px) {
        text-align: center;
    }
}

//new-component-style

// .timezone-selector{
//     position: relative;
//     width: 100%;
// }
// .timezone-selector *{
//     user-select: none;
// }
// .timezone-selector input{
//     width: 100%;
//     height: 36px;
//     padding-right: 10px;
//     padding-left: 5px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     border: 1px #DFE3EB solid;
//     border-radius: 4px;
// }
// .timezone-selector input:focus{
//     outline: none;
// }
// .img-dd{
//     position: absolute;
//     top: 0;
//     right: 5px;
//     height: 100%;
//     // background-color: #6a78d1;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
// }
// .img-dd-rotate{
//     transform: rotate(180deg);
// }
// .timezones{
//     width: 100%;
//     height: 250px;
//     position: absolute;
//     bottom: 100%;
//     left: 0;
//     overflow-y: scroll;
//     background-color: white;
//     z-index: 5000;

//     &::-webkit-scrollbar {
//         height: 8px;
//         width: 8px;
//     }
//     &::-webkit-scrollbar-track {
//         background-color: #f0f0f0;
//         border-radius: 20px;
//     }
//     &::-webkit-scrollbar-thumb {
//         background-color: #D6DFE8;
//         border-radius: 20px;
//     }
// }
// .zones{
//     padding: 4px 0;
// }
// .zones:hover{
//     background: #eee;
// }
// .current-zone, .current-zone:hover{
//     background-color: #ddd;
// }

.clock-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 100;
    background-color: #a5b6c9;
    border-radius: 50%;
    right: 0;
}

.btns {
    height: 36px;
    padding: 2px 0;
    margin: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btns:hover {
    background-color: #eaf0f6;
}

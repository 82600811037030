.social-first-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1000000000000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;

    .social-loading-wrapper {
        width: 80px;
        height: 80px;
        animation: growItUp 1500ms ease 0s infinite;
        position: absolute;
        top: 38%;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    .social-loading-tips{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        flex-direction: column;
        position: absolute;

        .social-loading-tips-description{
            font-size: 1rem;
            font-weight: 500;
            max-width: 40vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            color: #7C98B6;
        }
    }
}

@keyframes growItUp {
    0% {
        width: 80px;
        height: 80px;
    }
    50% {
        width: 90px;
        height: 90px;
    }
    100% {
        width: 80px;
        height: 80px;
    }
}

.aui-linkedin-slider {
    width: 500px;
    max-width: 100%;
    max-height: 565px;
    overflow: hidden;
    background-color: #fff;
    position: relative;

    .fileName {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0.15rem 0.3rem;
        background-color: rgba(0, 0, 0, 0.75);
        color: white;
        text-align: left;
        height: unset;
        z-index: 1;
        font-weight: 400;
        span {
            font-weight: 200;
            color: #fff;
            margin: 0.5rem;
        }
    }
    .bottombar {
        position: absolute;
        display: flex;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0.15rem 0.3rem;
        background-color: rgba(0, 0, 0, 0.75);
        color: white;
        text-align: left;
        align-items: center;
        height: 2rem;
        z-index: 1;
        .progressCount {
            position: relative;
            font-weight: 400;
            font-size: 1rem;
            margin: 0.5rem;
            color: #fff;
        }
        input {
            position: absolute;
            left: 60px;
            width: 80%;
            -webkit-appearance: none;
            height: 2px;
            background-color: #ddd;
            color: #fff;
        }
        input::-webkit-slider-thumb {
            -webkit-appearance: none;
            background-color: #fff;
            height: 10px;
            width: 10px;
            border-radius: 50%;
        }
    }
    ul {
        height: 100%;
        list-style: none;
        display: flex;
        flex: auto;
        white-space: nowrap;
        transition: transform 200ms ease-in;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            background: transparent;
        }

        li {
            height: 100%;
            margin: auto;
            position: relative;

            > div {
                height: 100%;
                overflow: hidden;
            }
        }
    }

    ul li img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &-scroll-controls {
        position: absolute;
        top: 50%;
        left: 10px;
        right: 10px;
        display: flex;
        justify-content: space-between;

        span {
            cursor: pointer;
            width: 1.5rem;
            height: 1.5rem;
            // background-color: #111111d7;
            border-radius: 50%;
            svg {
                fill: #fff;
            }
        }
    }
}

.aui-link-thumb-nail {
    display: flex;
    text-decoration: none;
    color: black;
    min-width: 240px;
    border-radius: 6px;
    background-color: #eef2f78a;
    box-shadow: 0 -3px 6px rgba(205, 215, 227, 0.568627);
    border: 1px solid #dde5ec;
    transition: all 0.1s ease;
    width: 100%;
    max-width: 100%;

    &:hover {
        text-decoration: none !important;
    }

    span {
        width: 100%;
        display: inline-block;
        text-align: left;
    }
    &-icon {
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        overflow: hidden;

        > img {
            border-radius: inherit;
            width: 100%;
            height: auto;
        }
    }

    &-data {
        min-width: 200px;
    }

    &-title {
        font-weight: 500;
        font-size: 16px;
        margin-top: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
    }

    &-description {
        font-size: 13px;
        color: rgba(41, 39, 39, 0.75);
    }

    &-link {
        margin-top: 0.7rem;
        font-size: 11px;
        color: rgba(46, 45, 45, 0.75);
    }

    &-row {
        padding: 6px;
        align-items: center;
        gap: 1rem;
    }

    &-row &-title {
        margin-bottom: 0.5rem;
    }

    &-row &-icon {
        max-width: 240px;
    }

    &-column {
        margin: 0.25rem 0.75rem;
        flex-direction: column;
        width: auto;
    }

    &-column &-icon {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &-column &-data {
        padding: 0rem 0.5rem 0.25rem 0.5rem;
    }

    &-column &-link {
        margin-top: 0.25rem;
        font-size: 12px;
    }
}

.media-picker {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 12px 0;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // z-index: 100000000000;
    background-color: #fff;
}

.pageTitle {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1.4rem;
    margin-bottom: 1rem;
}

.media-picker .img-col {
    width: 100%;
    padding: 0px 12px;
}

.image-content {
    margin-top: 1rem;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    scrollbar-width: thin;

    &-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .loader-ref {
        width: 100%;
    }
}
.media-Search {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.media-btn {
    cursor: pointer;
    padding: 12px 16px;
    height: 100%;
    background-color: black;
    color: #fff !important;
    line-height: 135%;
    border-radius: 4px;
}
.card {
    width: 100%;
    position: relative;

    .unsplas-creator {
        padding: 12px 14px;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(181deg, rgb(15 15 15 / 84%) 20.4%, rgb(255 255 255 / 0%) 95.2%);
    }
}
.card_picture {
    width: 100%;
    flex: 100%;
    background-color: coral;
    margin-top: 1rem;
}

.card .image-select-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
}

.final-checkout-model p {
    margin: 0;
    font-weight: 600;
}
.final-checkout-model .btn {
    cursor: pointer;
    width: 100px;
    height: 40px;
    background-color: black;
    color: #fff !important;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.final-checkout-model .btn span {
    color: #fff !important;
}

.media-content {
    height: 93%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    &-wrapper {
        position: relative;
        height: 100%;
        max-height: 100%;
    }
}

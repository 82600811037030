.aui-preview-file {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &-backdrop {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: #343f535e;
        top: 0;
        left: 0;
        z-index: 1000000000000003;
    }

    &-preview-cancel {
        position: absolute;
        top: 6px;
        right: 5px;
        font-size: 1.5rem;
        z-index: 10;
        background-color: #33475b3a;
        transform: rotate(45deg);
        color: #fff;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        height: 48px;
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        &:hover {
            background-color: #2e3f50b4;
        }
    }
}

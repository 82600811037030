.aui-input {
    width: 400px;
}
.aui-input-label {
    color: #33475b;
    padding-bottom: 8px;
    font-size: 1.089rem;
    font-weight: 500;
}

.aui-input-wrapper {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #D6DFE8;
}
.unsplash-textarea {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    padding: 10px 12px;
    outline: none;
    color: #33475b;
    border: none;
}

textarea::placeholder,
textarea:-moz-placeholder {
    font-size: 0.8rem;
    font-weight: 500;
}

.input-icon {
    margin: 4px;
    width: 24px;
    margin-left: 6px;
    height: 24px;
    background-color: coral;
}

.unsplash-input {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    padding: 10px 12px;
    outline: none;
    color: #33475b;
    border: none;
}

.aui-input-select {
    outline: none;
    border: 1px solid #a2c8f1;
    box-shadow: 0px 0px 4px #a2c8f1;
}

.input-disabled input {
    background-color: #eaf0f6;
}

.input-label-spacer {
    margin-top: 0.5rem;
}

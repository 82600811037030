.aui-activity-log {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-head {
        color: #33475b;
        font-size: 1.5rem;
        font-weight: 500;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 2rem;
        width: 100%;
        border-bottom: 1px solid #dbe3e9a1;
    }

    &-wrapper {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        position: relative;
    }

    &-strip {
        display: flex;
        align-items: center;
        gap: 1rem;
        min-height: 48px;
        border: 1px solid transparent;
        border-bottom: 1px solid #dbe3e9a1;
        padding: 0.35rem 2rem;
        width: 100%;
        animation: fade-in 0.3s ease-in 1;

        &-top {
            display: flex;
            gap: 1rem;
            align-items: center;
        }

        &-name {
            color: #33475b;
            font-size: 0.9rem;
            font-weight: 500;
        }
        &-comment {
            text-align: left;
            color: #33475b;
        }
        &-bottom {
            text-align: left;
            color: #99acc2;
            font-size: 0.8rem;
        }
    }
}

.file-bar {
    scrollbar-width: thin;
    overflow-x: auto;
    overflow-y: hidden;
    &-container {
        display: flex;
        align-items: center;
        padding: 0.25rem;
    }
    .file-images {
        // display: inline-flex;
        // flex: 0 0 125px;
        min-width: 100px;
        min-height: 125px;
        height: 125px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        margin-right: 12px;
        margin-bottom: 12px;
        border: 1px solid #677b8c;
        cursor: pointer;
        margin: 0 3px;
        background-color: #fff;

        .file-image-cancel {
            position: absolute;
            top: 6px;
            right: 5px;
            font-size: 1.5rem;
            z-index: 10;
            background-color: #33475b77;
            transform: rotate(45deg);
            color: #fff;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            border-radius: 50%;
            cursor: pointer;
            overflow: hidden;
            &:hover {
                background-color: #2e3f50b4;
            }
        }

        img,
        svg {
            height: 100%;
            object-fit: contain;
            transition: transform 100ms ease-in;
        }
        &:hover {
            svg,
            img {
                transform: scale(1.1);
            }
        }
    }

    &-uploading {
        display: flex;
        height: 125px;
        .file-images {
            img {
                filter: blur(2px);
            }
        }
    }
    .file-box {
        width: 125px;
        height: 125px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        margin-right: 12px;
        margin-bottom: 12px;
        border: 1px solid #677b8c;
        cursor: pointer;
        margin: 0 3px;

        > div {
            filter: blur(2px);
            background-color: rgba(173, 216, 230, 0.281);
            width: 100%;
            height: 100%;
        }
    }
    &-uploaded {
        height: 125px;
        display: flex;
        .file-images {
            img {
                animation: uploaded-fade-in 0.1s ease-in;
            }
        }
    }
}

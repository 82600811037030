@import "../../../../mixin";
.aui-post-manager-section {
    min-width: 40%;
    width: 50rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid #D6DFE8;
    border-right: 1px solid #D6DFE8;

    @media screen and (max-width: 700px) {
        width: 100%;
    }

    &-wrapper {
        display: flex;
        flex: 1 1;
        width: 100%;

        > div {
            &:first-child {
                flex: 1 1;
                width: 100%;
                height: calc(100vh - 64px);
                overflow-y: auto;
                max-width: calc(100% - 56px);

                @include scroll;

                @media (max-width: 1200px) {
                    max-width: calc(100% - 44px);
                }
            }
        }
    }
    &-edit-section {
        display: flex;
        flex-direction: column;
    }
}

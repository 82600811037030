.aui-post-manager-sidenav {
    width: 56px;
    min-width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #273240;
    // margin-top: 9px;

    @media (max-width: 1200px) {
        width: 44px;
        min-width: 44px;
    }

    &-option {
        width: 100%;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        > div,
        path {
            width: 28px;
            height: 28px;
            background-color: #97a3b4;
            fill: #97a3b4 !important;
        }

        svg {
            max-width: 28px;
        }

        > img {
            width: auto;
            height: 28px;
        }

        &-active {
            background-color: #3c4c5e;

            > div,
            path {
                background-color: white;
                fill: white !important;
            }
        }

        &-disabled {
            cursor: not-allowed;

            > div,
            path {
                background-color: #6c747f !important;
                fill: #6c747f !important;
            }
        }

        &:hover {
            > .tooltip {
                visibility: visible;
            }
        }
    }
}
.aui-post-manager-sidenav-white {
    width: 56px;
    min-width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // background-color: #273240;
    background-color: white;
    margin-top: 9px;

    @media (max-width: 1200px) {
        width: 44px;
        min-width: 44px;
    }

    @media (max-width: 550px) {
        border-left: 1px solid #D6DFE8;
    }

    &-option {
        width: 100%;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        > div,
        path {
            width: 28px;
            height: 28px;
            background-color: #425b76;
            fill: #5e85af !important;
        }

        svg {
            max-width: 28px;
        }

        > img {
            width: auto;
            height: 28px;
        }

        &-active {
            background-color: white;

            > div,
            path {
                background-color: #425b76;
                fill: #425b76 !important;
            }
        }

        &-disabled {
            cursor: not-allowed;

            > div,
            path {
                background-color: #425b76 !important;
                fill: #425b76 !important;
            }
        }

        &:hover {
            > .tooltip {
                visibility: visible;
            }
        }
    }
}

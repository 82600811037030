.emoji-picker-react {
    z-index: 100;
    position: absolute !important;
    bottom: 0;
    transform: translateY(100.5%);
    left: 0;
    height: 280px;
    box-shadow: 5px 5px 6px rgba(128, 128, 128, 0.25);
}

.aui-emoji-picker {
    display: flex;
    align-items: center;
    justify-content: center;

    &-smiley {
        height: 100%;
        width: auto;
        border: none;
        outline: none;
        font-size: 1.5rem;
        background-color: transparent;
        cursor: pointer;

        .emojiLogo {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.35rem;
            border-radius: 50%;

            &:hover {
                background-color: #e5f5f8;

                .emojiIcon {
                    path {
                        fill: #00bedb;
                    }
                }
            }
        }
    }
}

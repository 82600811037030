@import "../../../mixin";

.help-sidenavbar {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    border: 1px solid #D6DFE8;
    background-color: #fff;
    border-top: none;
    box-shadow: 3px 0px 8px #425b7681;
    @include scroll;

    @media (max-width:480px){
        max-width: 350px;
    }

    .joinGroup{
        margin-right: 2rem;
        margin-left: 1rem;
    }

    > .background {
        background-image: url("./images/background.png");
        width: 100%;
        height: 180px;
        box-shadow: inset 0px 3px 6px #425b7638;
    }
}

.aui-toggle-button {
    border: 1px solid #D6DFE8;
    border-radius: 100px;
    padding: 0;
    background: #D6DFE8;
    width: 3.5rem;
    height: 1.5rem;
    transition: all 0.1s ease;
    cursor: grab;

    &-circle {
        width: 1.5rem;
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
        transition: all 0.1s ease;
    }

    &-active {
        background-color: #25c16f;
        border-color: #25c16f;

        > div {
            margin-left: auto;
        }
    }
}

.aui-media-selection-dialog-nav {
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    min-height: 42px;
    padding: 0 1rem;
    z-index: 1;
    box-shadow: rgba(221, 226, 231, 0.643) 0px 3px 6px 0px;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0%;
        content: "";
        width: 100%;
        height: 1px;
        background-color: rgb(237, 242, 247);
    }

    &-tab {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        font-size: 1rem;
        height: 100%;
        cursor: pointer;
        z-index: 2;

        &-active {
            position: relative;
            color: rgb(37, 99, 235);
            transition: all 0.1s ease;
            &::after {
                position: absolute;
                bottom: 0;
                left: 0%;
                content: "";
                width: 100%;
                height: 1px;
                background-color: rgb(37, 99, 235);
            }
        }
    }
}

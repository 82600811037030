.joinGroup{
    margin-top: auto;
    border: 1px solid #EAF0F6;
    background: rgb(234,240,246);
    background: linear-gradient(90deg, rgba(234,240,246,1) 0%, rgba(180,187,232,1) 100%);
    border-radius: 5px;
    box-shadow: 0px 3px 6px #CEE0F0;
    margin-left: 8px;
    margin-right: 12px;
    margin-bottom: 15px;

    .joinGroupTopPart{  
        padding: 1.1rem 3rem 0 3rem;

        .joinGroupHeading{
            font-size: 1.2rem;
            font-weight: bold;
            color: #5E6AB8;
            text-align: left;
        }

        .joinGroupText{
            font-size: 0.85rem;
            color: #425B76;
            text-align: left;
            margin-top: 1rem;
        }

        .joinGroupButtonContainer{
            display: flex;
            align-items: center;
            justify-content: center;

            .joinGroupButton{
                background-color: #6A78D1;
                color: white;
                font-size: 1rem;
                font-weight: 700;
                letter-spacing: 0.04rem;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                cursor: pointer;

                &:hover{
                    box-shadow: 0px 0px 5px #6A78D1;
                    opacity: 0.95;
                }
            }
        }
    }

    .joinGroupBottomPart{
        display: flex;
        align-items: center;
        justify-content: center;

        .joinGroupImage{
            margin-top: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}
@import "../../../mixin";

.userInputContainer{
    height: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .userInputField{
        border: 1px solid #D6DFE8;
        height: 2.7rem;
        border-radius: 4px;
        

        .userInput{
            border: none;
            outline: none;
            height: 100%;
            padding: 0.4rem 0.8rem;
            min-width: 15rem;
            max-width: 25rem;
            width: 20rem;
        }
        .longInput {
            border: none;
            outline: none;
            height: 100%;
            padding: 0.4rem 0.8rem;
            min-width: 10rem;
            max-width: 25rem;
            width: max(25rem);
            @media (max-width:768px) {
                width: 10rem;
            }
        }
    }
    
    .userDropdownOne {
        min-width: 6rem;
        max-width: 15rem;
        width: 8rem;
        height: 2.7rem;
        border: 1px solid #D6DFE8;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.4rem 0.8rem;
        margin-left: 0.4rem;
        position: relative;

        &.increaseWidth {
            width:10rem;
            @media (max-width:768px) {
                width: 5rem;
            }
        }
        .dropDownValue{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;

            .currentDropdownValue{
                width: calc(100% - 0.8rem);
                display: flex;
                align-items: center;
                justify-content: left;
                height: 100%;
                max-width: calc(100% - 0.8rem);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .dropdownArrow{
                width: 0.8rem;
                height: 100%;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        .dropDownList{
            position: absolute;
            top: 100%;
            right: 0;
            max-height: 18rem;
            overflow-y: auto;
            overflow-x: hidden;
            width: 21rem;
            background-color: #fff;
            box-shadow: 0 3px 6px #D6DFE8;
            padding: 0;
            border-radius: 4px;
            z-index: 999;

            @include scroll;

            .dropDownListItem{
                width: 21rem;
                height: 5rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .listItemWrapper{
                    height: 4rem;
                    width: 18rem;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    border: 1px solid #7FD1DE;
                    border-radius: 4px;
                    cursor: pointer;

                    &:hover{
                        background-color: #e5f5f8b4;
                    }

                    &-selected{
                        border: 1px solid #079DC6;
                        box-shadow: 1px 2.5px 3px #079dc679;
                        background-color: #E5F5F8;
                    }

                    .checkBox{
                        width: 10%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .listItemIcon{
                        max-width: 3rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 0.4rem;

                        img{
                            max-width: 3rem;
                            max-height: 3rem;
                            width: 3rem;
                            height: 3rem;
                            object-fit: cover;
                        }
                    }

                    .listItemContent{
                        width: auto;

                        .listItemHeading{
                            font-weight: 500;
                            max-width: 12.5rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-align: left;
                        }

                        .listItemDesc{
                            font-size: smaller;
                            color: #7C98B6;
                            max-width: 12.5rem;
                            text-align: left;
                        }
                    }
                }

                &:first-child{
                    padding-top: 0.5rem;
                }

                &:last-child{
                    padding-bottom: 0.5rem;
                }
            }
        }
    }

    .userButton{
        height: 2.7rem;
        width: 6rem;
        background-color: #6A78D1;
        border: 1px solid #5E6AB8;
        color: white;
        margin-left: 0.5rem;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 0.35rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;

        &:hover{
            opacity: 0.95;
            cursor: pointer;
        }
    }

    .cancelButton{
        height: 2.5rem;
        min-width: 2.5rem;
        font-size: 2.3rem;
        font-weight: 300;
        background-color: #FDEDEE;
        color: #F2545B;
        border-radius: 50%;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.5rem;
        cursor: pointer;

        &:hover{
            opacity: 0.9;
        }
    }
}

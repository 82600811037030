.aui-trial-ended {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #2d394c73;
    z-index: 1000000000000;

    &-popup {
        position: absolute;
        max-width: 542px;
        width: 95%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        animation: grow 0.1s ease-in 1;
        transform-origin: center;
    }

    &-image {
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-title {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 1.5rem;
    }

    &-sub-title {
        margin: 0 1.5rem 1.5rem 1.5rem;
        font-size: 13px;
    }

    &-bottom {
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 1rem;
        position: relative;
    }
    &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 1rem;

        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 380px;
            height: 2.5rem;
            border-radius: 4px;
            cursor: pointer;
            font-weight: 500;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &-payment-button {
        background-color: #00a7fd;
        border: 1px solid #00a7fd;
        color: white;
        width: 80%;
    }

    &-all-plans {
        cursor: pointer;
        color: #00a4bd;
        margin: 1rem auto 1rem auto;
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }
    }

    &-logout {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        cursor: pointer;
        // margin: 1rem 1rem 1rem 1rem;
        display: inline-block;
        color: #e78d7d;
        font-size: 0.9rem;
        font-weight: bold;
        margin-left: auto;

        &:hover {
            text-decoration: underline;
        }
    }

    &-close {
        position: absolute;
        top: 0rem;
        right: 1rem;
        font-size: 2rem;
        font-weight: 100;
        transform: rotateZ(45deg);
        cursor: pointer;

        &:hover {
            font-weight: 200;
        }
    }
}

@keyframes grow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.aui-preview-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    height: 100%;
    background-color: #f5f8fa;
    overflow-y: auto;

    @media (max-width: 992px) {
        display: none;
    }

    &-post-preview {
        // padding: 1rem;
        width: 100%;
        position: relative;
        height: auto;

        > p {
            margin: 1rem auto 2rem auto;
            font-style: italic;
        }

        #preview-icon {
            position: absolute;
            right: -0.7rem;
            top: -0.7rem;
            width: 32px;
            height: 32px;
        }

        .preview-header {
            display: flex;
            width: 100%;
            padding: 0.5rem 1rem;
            align-items: center;
            justify-content: left;
            flex-wrap: wrap;
            border-bottom: 1px solid #d6dfe8;
            background-color: white;
        }

        .header-label {
            color: #425a75;
            margin-right: 1rem;
        }

        .dropdown-wrapper {
            border: 1px solid #d6dfe8;
            border-radius: 4px;
        }

        .radius {
            div {
                &:first-child {
                    border-radius: 4px;
                }
            }
        }

        .preview-container {
            margin-bottom: 2rem;
            padding: 0.5rem;
        }
    }
}

.open-post-link {
    margin-top: 1rem;
    > a {
        font-size: 0.7rem;
        color: #00aeef;
        border: 1px solid #d6dfe8;
        background-color: white;
        border-radius: 3px;
        padding: 8px 16px;
        position: relative;

        span {
            width: 0.7rem;
            height: 0.7rem;
            display: block;
            position: absolute;
            top: 0.2rem;
            right: 0.2rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.resource-center-card {
    display: flex;
    align-items: center;
    background: rgb(255, 255, 255);
    border: 0.5px solid #D6DFE8;
    border-radius: 6px;
    box-shadow: 2px 3px 6px #425b762a;
    padding: 10px;
    margin-bottom: 9px;
    width: 96%;
    height: 80px;
    max-height: 80px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    &:hover {
        box-shadow: 2px 3px 6px #425b7652;
    }

    > div {
        max-height: 100%;
        > h6 {
            font-size: 22px;
            font-weight: 600;
            color: #33475b;
            margin-bottom: 1px;
            text-align: left;
            margin-left: 12px;

            @media (max-width:480px){
                font-size: 16px;
                font-weight: 500;
            }
        }

        > p {
            margin-left: 12px;
            font-size: 13px;
            color: #7c98b6;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 19rem;

            @media (max-width:480px){
                font-size: 11px;
                text-align: left;
            }
        }

        > img {
            display: inline-block;
            width: auto;
            max-height: 50px;
            height: 100%;
        }
    }

    #img-container {
        padding: 4px;
        border-radius: 4px;
    }

    > img {
        margin-left: auto;
        width: 20px;
        height: 20px;
    }
}

@import "../../../../mixin";

.aui-post-edit-section {
    padding: 1rem 1rem;
    overflow-y: auto;
    border-right: 1px solid #d6dfe8;
    flex: 1;
    width: 100%;
    @include scroll;

    > div {
        margin-bottom: 1rem;
    }

    > ul.post-type {
        display: flex;
        align-items: center;
        gap: 1rem;
        list-style: none;
        padding-inline: 0.5rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #d6dfe8;
        > .post-type--item {
            position: relative;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            cursor: pointer;
            &.active {
                color: #2e3f50;
                > svg {
                    & * {
                        fill: #2e3f50;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #2e3f50;
                    border-radius: 1rem;
                }
            }
            > .post-type--radio {
                --radio-color: #d6dfe8;
                position: relative;
                width: 0.75rem;
                height: 0.75rem;
                margin-right: 0.25rem;
                border-radius: 50%;
                outline: 1px solid var(--radio-color);
                outline-offset: 0.1rem;
                cursor: pointer;
                &.active {
                    --radio-color: #2e3f50;
                    outline-width: 2px;
                    background-color: var(--radio-color);
                }
            }
        }
    }

    .post-files {
        min-height: 125px;
        border: 1px solid #d6dfe8;
        border-radius: 6px;
        margin-top: 0.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        overflow: hidden;

        > div {
            &:nth-child(3) {
                flex: 1;
            }
        }

        > #add-image {
            width: 120px;
            height: 120px;
            min-width: 120px;
            min-height: 120px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #eaf0f6;
            margin: 0.35rem;
            font-size: 2rem;
            cursor: pointer;
        }
    }

    .files-wrapper {
        text-align: left;
        margin-top: 1.5rem;

        .icon {
            img {
                max-height: unset;
            }
        }
    }

    > .postTo {
        .post-editor-head {
            display: flex;
            align-items: center;

            > .head {
                display: inline-block;
                display: flex;
                // justify-content: center;
                align-items: center;
                font-size: 0.9rem;
                color: #2e3f50;
                border-radius: 4px;
                background-color: #ebf1f8;
                border: 1px solid #ccdff2;
                height: 1.7rem;
                max-width: 7rem;
                padding: 0 0.5rem;
                text-align: left;
            }
        }

        > .container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 4px;
            padding: 0 0 0 0.5rem;
            margin: 0.5rem 0;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }

            > .platformWrapper {
                display: flex;
                flex-direction: row;
                border: 1px solid #ccdff2;
                border-right: none;
                overflow-x: auto;
                scrollbar-width: none;
                flex-grow: 1;
                height: 90px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                .channelWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    > .channel {
                        position: relative;
                        align-items: center;
                        justify-content: center;
                        margin: 0.65rem;
                        padding: 2px;
                        width: 3.75rem;
                        height: 3.75rem;
                        border-radius: 50%;
                        background: #dde6ef;
                        border: 0;

                        @media (max-width: 1600px) {
                            margin: 0.6rem;
                            width: 3.25rem;
                            height: 3.25rem;
                        }
                        @media (max-width: 1200px) {
                            margin: 0.55rem;
                            width: 2.75rem;
                            height: 2.75rem;
                        }

                        &:first-child {
                            // margin-left: 0;
                        }

                        > span svg {
                            width: 100%;
                            height: 100%;
                            z-index: 9;
                            border-radius: 50%;
                            g .st0 {
                                fill: #1da1f2;
                            }
                            g .st1 {
                                fill: #ffffff;
                            }
                            g .st2 {
                                fill: #1877f2;
                            }
                            g .st3 {
                                fill-rule: evenodd;
                                clip-rule: evenodd;
                                fill: #e1306c;
                            }
                            .st4 {
                                fill-rule: evenodd;
                                clip-rule: evenodd;
                                fill: #0077b5;
                            }
                        }

                        .overlay {
                            position: absolute;
                            transition: all 0.3s ease;
                            border-radius: 50%;
                            z-index: 10;
                            line-height: 140%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }

                        .profileStatus {
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            z-index: 10;
                            transform: translate(-40%, -15%);
                            width: 1.2rem;
                            height: 1.2rem;
                        }

                        &-select {
                            border: 3px solid #00acee;
                        }

                        &-active {
                            .overlay {
                                transform: rotate(45deg);
                                width: 1.2rem;
                                height: 1.2rem;
                                font-size: 1rem;
                                font-weight: 400;
                                top: -0.25rem;
                                right: -0.25rem;
                                background-color: black;
                                color: white;
                            }

                            &:hover {
                                .overlay {
                                    display: flex;
                                    background: rgba(0, 0, 0, 0.8);
                                    color: white;
                                    opacity: 1;
                                }
                            }
                        }

                        &-inactive {
                            > span svg {
                                width: 100%;
                                height: 100%;
                                z-index: 9;
                                border-radius: 50%;

                                g .st0,
                                circle {
                                    fill: #d6dae2 !important;
                                }
                                g .st1 {
                                    fill: #ffffff !important;
                                }
                                g .st2 {
                                    fill: #f1f2f2 !important;
                                }
                                > .st3 {
                                    fill-rule: evenodd;
                                    clip-rule: evenodd;
                                    fill: #f1f2f2 !important;
                                }
                                > .st4 {
                                    fill-rule: evenodd;
                                    clip-rule: evenodd;
                                    fill: #f1f2f2;
                                }
                                #Group_User {
                                    circle {
                                        fill: #ffffff !important;
                                    }
                                    path {
                                        fill: #ffffff !important;
                                    }
                                }

                                #company {
                                    fill: #ffffff !important;
                                }
                            }
                            path {
                                fill: #d6dae2;
                            }

                            .twitter-icon-logo {
                                fill: #fff;
                            }
                            .overlay {
                                width: 100%;
                                height: 100%;
                                font-size: 2rem;
                                font-weight: 600;
                                top: 0;
                                right: 0;
                                transform: rotate(0deg);
                            }
                            &:hover {
                                cursor: pointer;

                                svg {
                                    opacity: 0.5;
                                }

                                .overlay {
                                    display: flex;
                                    background: rgba(0, 0, 0, 0.2);
                                    color: white;

                                    > div {
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }

                    .bottomStatusBar {
                        position: absolute;
                        top: 93%;
                        height: 6px;
                        width: 80%;
                        background-color: #00acee;
                        border-top-left-radius: 40px;
                        border-top-right-radius: 40px;
                    }
                }

                .connectWrapper {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .media-add {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 2.75rem;
                    min-height: 2.75rem;
                    margin: 0.55rem;
                    font-size: 1.5rem;
                    border-radius: 50%;
                    border: 2px dashed #cdd2d6;
                    position: relative;
                    cursor: pointer;
                    color: #cdd2d6;

                    &:hover {
                        border: 2px dashed #00bedb;
                        background-color: #e5f5f8;
                        color: #00bedb;
                    }
                }
            }

            .postDuplication {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 8rem;
                height: 90px;
                margin-left: auto;

                .postSimilarPost {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    cursor: pointer;
                    font-size: 0.7rem;
                }

                .postCustomizedPost {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    cursor: pointer;
                    font-size: 0.7rem;
                }

                .activeDuplication {
                    background-color: #e2f6ff;
                    color: #00acee;
                    border: 1px solid #00acee;
                }
                .activeDuplicationCustom {
                    background-color: #fff3e9;
                    color: #f39449;
                    border: 1px solid #f39449;
                }
            }
        }
    }

    .post-contents {
        .post-contents-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .post-title {
                margin-bottom: 12px;
                margin-top: 32px;
                font-size: 1.1rem;
                text-align: left;
                color: #2e3f50;
                font-weight: 600;
            }
            .post-add-other {
                display: flex;
                align-items: center;
                .post-add-from {
                    cursor: pointer;
                    color: #3ba6de;
                    font-weight: 500;
                    padding: 6px 4px;
                    border-radius: 4px;
                    &:hover {
                        background-color: #84afbf26;
                    }
                }
                .media-icon {
                    height: 26px;
                    border-radius: 50%;
                    margin-right: 12px;
                    cursor: pointer;

                    > div {
                        max-height: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        gap: 0.25rem;
                        margin: 0 12px;
                        border: 1px solid #b9d3ec;
                        background-color: #ebf1f8;
                        padding: 6px;
                        border-radius: 4px;
                    }

                    #icon {
                        display: inline-flex;
                        max-height: 100%;
                        height: 100%;
                        img {
                            max-height: 100%;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                    svg {
                        font-size: 0.6rem;
                    }
                }
            }
        }
    }
    .post-failure-msg {
        font-size: 0.65rem;
        margin-left: 1rem;
        width: 95%;
        padding: 8px 0;
        border-radius: 6px;
        background-color: #e8384f0c;
        border: 1px solid #e8384f;
        color: #e8384f;
    }
}

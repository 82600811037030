.aui-tag-list {
    height: 3rem;
    // min-width: 10rem;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    // margin-right: 95%;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    text-align: left;
    border: none;
    transition: width 0.3s ease;

    &-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-left: 0.25rem;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
    }
    &-item {
        display: flex;
        align-items: center;
        border: 1px solid #99acc2;
        border-radius: 3px;
        cursor: pointer;
        margin-right: 0.5rem;

        > span {
            &:first-of-type {
                margin-right: 0.4rem;
            }
            &:last-child {
                width: 1rem;
                height: 1rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 1.25rem;
                color: white;
            }
        }

        &:first-child {
            margin-left: auto;
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

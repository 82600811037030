@import "../../../mixin";

.aui-list-picker {
    position: relative;
    z-index: 2;

    &-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            position: relative;
            padding: 0.1rem 0.4rem 0.1rem 0.6rem;
            left: -0.5rem;
            border-radius: 0 12px 12px 0;
            border: 1px solid #ccdff2;
            z-index: 0;
        }
    }

    &-dropdown {
        width: 12rem;
        background-color: #ffffff;
        position: absolute;
        top: 0%;
        border-radius: 2px;
        right: 0;
        border: 1px solid lightgray;
        z-index: 2;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);

        &-right {
            left: 0;
            right: unset;
        }
    }

    &-cross {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border: 1px dashed lightgray;
        border-radius: 50%;
        transform: rotateZ(45deg);
        font-size: 1.5rem;
        cursor: pointer;
    }

    &-search {
        height: 1.885rem;
        border-bottom: 1px solid lightgray;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin: 0.25rem;
        padding: 0.1rem 0.2rem;
        width: 100%;
        max-width: 100%;

        > input {
            flex: 1;
            border: none;
            outline: none;
            color: rgba(128, 128, 128, 1);
            font-weight: 600;
            max-width: 89%;
        }
    }

    &-container{
        max-height: 12rem;
        overflow: auto;
        @include scroll;

        &-list {
            margin-top: 0.25rem;
            max-height: 15rem;

            &-item {
                padding: 0.12rem 0.3rem;
                cursor: pointer;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                border-bottom: 1px solid #f4f4f4;
    
                > span {
                    font-weight: 600;
                    margin-left: 0.5rem;
                }
    
                &:hover {
                    background-color: #f1f1f1;
                }
            }
        }
    }
}

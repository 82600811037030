.aui-post-text-area {
    text-align: left;
    margin-bottom: 2rem;

    #post-management-title {
        .icon {
            img{
                max-height: unset;
            }
        }
    }

    > .input {
        margin-top: 0.6rem;
        border-radius: 8px;
        border: 1px solid #CCDFF2;
        position: relative;

        > textarea {
            padding: 1rem;
            padding-bottom: 0;
            outline: none;
            font-size: 0.85rem;
            border: none;
            width: 100% !important;
            height: 8rem !important;
            background: rgb(247, 250, 252);
            resize: none;
            border-radius: inherit;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .text-remain {
            color: #ffc107;
        }
        .text-danger {
            color: #f2545c;
        }
        .post-text-validation {
            position: absolute;
            bottom: 3.5rem;
            right: 10px;
        }

        > .extras {
            display: flex;
            align-items: center;
            border-top: 1px solid #CCDFF2;

            .extra {
                border-right: 1px solid #CCDFF2;
                height: 30px;
                padding: 0 0.35rem;

                svg {
                    height: 1rem !important;
                    width: 1rem !important;
                }
            }

            > * {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            > button {
                color: #707070;
                cursor: pointer;
                margin-right: 0.5rem;
                border: none;
                outline: none;
                background: transparent !important;
                font-style: italic;

                &:first-child {
                    font-size: 1.5rem;
                    font-style: normal;
                    // height: 1.75rem;
                }

                > input {
                    border: none;
                    outline: none;
                    width: 3rem;
                    background: transparent;
                }
            }

            .media-button {
                padding: 0.35rem;
                border-radius: 50%;
                cursor: pointer;
                &:hover {
                    background-color: #E5F5F8;
                    path {
                        fill: #00BEDB;
                    }
                }
            }
        }
    }
    .link-minify {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.75rem;
    }
    > .focusText {
        border: 1px solid #00abeed3;
        // box-shadow: 0 0 3px #00acee;
        box-shadow: 0 3px 6px #dde2e7a4;
        background: #fff;
    }
}

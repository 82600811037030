.aui-post-manager-creator-bar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem;

    @media (max-width: 1200px) {
        gap: 0.25rem;
        padding: 0 0.5rem;
    }

    &-image {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #66788a;

        @media (max-width: 1200px) {
            width: 40px;
            height: 40px;
        }

        > div {
            width: 100%;
            height: 100%;
            min-height: 100%;
        }
        &-blank {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px dashed #66788a;
            background-color: white;

            @media (max-width: 1200px) {
                width: 40px;
                height: 40px;
            }
        }
    }

    > div {
        &:not(:nth-child(1)) {
            height: 100%;
            margin-right: 3rem;

            @media (max-width: 1200px) {
                margin-right: 1.5rem;
            }
        }

        > div {
            text-align: left;
            line-height: 1.5rem;
            &:nth-child(1) {
                font-size: 0.7rem;
            }
            &:nth-child(2) {
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }

    &-status {
        > div {
            &:nth-child(1) {
                text-align: center;
            }
            &:nth-child(2) {
                border: 1px solid #66788a;
                border-radius: 6px;
                color: #425a70;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 28px;
                min-width: 7rem;

                > span {
                    display: inline-block;
                    width: 0.75rem;
                    height: 0.75rem;
                    border-radius: 50%;
                    margin-right: 0.5rem;
                }
            }
        }
    }

    &-time {
        > div {
            &:nth-child(2) {
                color: #8d9ba8;
                font-weight: 400;
                font-size: 0.8rem !important;
            }
        }
    }
}

.pin-drop-w {
    position: relative;
    max-width: 300px;
    min-width: 300px;
    min-height: 45px;
    margin: 0 1rem 1rem 0;
    background-color: #fff;

    @media (max-width: 1250px) {
        max-width: 100%;
        margin-right: 0;
    }

    .boardLabel{
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
        margin-bottom: 0.25rem;
    }

    .pin-drop {
        padding: 2px 10px;
        border-radius: 4px;
        border: 1px solid #D6DFE8;
        height: 3rem;

        .pin-drop-select {
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .drop-arrow {
                font-weight: 700;
                padding: 0.5rem;
                border-radius: 50%;
                height: 2rem;
                width: 2rem;
            }
            .drop-arrow-down {
                font-weight: 600;
                transform: rotate(90deg);
            }
        }

        .pin-search {
            width: calc(100% - 12px);
            margin: 3px auto;
            border: 1px solid #CCDFF2;
            border-radius: 6px;
            .pin-in {
                outline: none;
                width: 78%;
                padding: 5px;
                border: none;
            }
        }

        .pin-drop-item {
            display: flex;
            cursor: pointer;
            .pin-title {
                line-height: 250%;
            }
        }
        .pin-div {
            height: 35px;
            padding: 0 15px;

            &:hover {
                background-color: #D6DFE88f;
            }
        }

        .pin-drop-image {
            width: 35px;
            height: 35px;
            object-fit: cover;
            background-color: red;
            margin-right: 1rem;
        }
    }

    .no-pin-drop {
        padding: 4px;
    }

    .pin-is-drop {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .pin-droper {
        box-shadow: rgba(149%, 157%, 165%, 20%) 0px 8px 24px;
        background-color: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border: 1px solid #D6DFE8;
        border-top: none;
        max-height: 200px;
        overflow: auto;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        z-index: 1000;
        border-radius: 3px;
        box-shadow: 0px 5px 16px -7px rgba(98,85,143,0.75);
    }
}

.createBoard{
    height: 2.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.9rem;
    color: #0ebdd8;
    cursor: pointer;
}

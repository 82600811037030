@import "../../../mixin";

.aui-created-workflow-component {
    padding: 2rem 1rem 2rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 1rem;
    animation: fade-in 0.3s ease;
    height: auto;
    // overflow-x: auto;
    overflow-y: visible;
    @include scroll;

    &-wrapper {
        display: flex;
        align-items: center;
        width: 95%;
    }

    .head {
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        color: #2e3f50;
        border-radius: 4px;
        background-color: #ebf1f8;
        border: 1px solid #ccdff2;
        height: 1.7rem;
        max-width: 7rem;
        padding: 0 0.5rem;
    }

    &-level {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        position: relative;

        &:hover {
            > div {
                margin-left: 0.6rem;
            }
        }

        &::after {
            position: absolute;
            content: "";
            width: 2rem;
            height: 2px;
            background-color: gray;
            left: 100%;
            top: 50%;
        }

        &:last-child {
            margin-right: 0;
            &::after {
                display: none;
            }
        }
    }

    &-approver {
        border-radius: 50%;
        margin-left: -0.6rem;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        > .avatar{
            padding: 2px;
        }

        > .approverStatusIcon{
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: inherit;

            > img{
                width: 100%;
                height: 100%;
            }
        }

        &:hover {
            z-index: 2;
        }

        &-approved {
            border: 1.5px solid;
            border-color: #00BDA5;
            background-color: white;
        }
        &-pending {
            border: 1.5px solid;
            border-color: #F5C26B;
            background-color: white;
        }
        &-awaited {
            border: 1.5px solid;
            border-color: #99ACC2;
            background-color: white;
        }
        &-rejected {
            border: 1.5px solid;
            border-color:  #F2545B;
            background-color: white;
        }
    }
    .showCollaborateTooltip{
        .aui-user-profile-tooltip-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .aui-user-profile-tooltip-bottom{
            display: none;
            top: unset;
            bottom: unset;
            right: unset;
            left: unset;
            transform: translate(-42.5% , 15%);
        }
        &:hover {
            .aui-user-profile-tooltip {
                display: block !important;
                z-index: 1000;
            }
            .aui-user-profile-tooltip-bottom{
                z-index: 1000;
            }
        }
    }
}

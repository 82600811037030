.aui-tag-icon {
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    transform: rotateZ(45deg);

    &:hover {
        background-color: #e5f5f8 !important;
        border-color: #00bedb !important;
        path {
            fill: #00bedb;
        }

        .tagIcon {
            background-color: #00bedb !important;
        }
    }
}

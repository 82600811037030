.aui-facebook-grid {
    width: 100%;
    max-height: 435px;
    overflow: hidden;
    display: grid;
    background-color: #e69393;

    div {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #00000066;
            > p {
                color: #ffffff;
                font-size: 30px;
                font-weight: 500;
                line-height: 145%;
            }
        }
    }

    div img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .photo-1 {
        grid-area: photo-1;
    }
    .photo-2 {
        grid-area: photo-2;
    }
    .photo-3 {
        grid-area: photo-3;
    }
    .photo-4 {
        grid-area: photo-4;
    }
    .photo-5 {
        grid-area: photo-5;
    }
}
.facebook-grid-2 {
    height: 250px !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5px;
    background-color: #ffff !important;
    grid-template-areas: "photo-1 photo-2";
}
.facebook-grid-3 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 2.5px;
    background-color: #ffff !important;
    grid-template-areas:
        "photo-1 photo-1 photo-1 photo-1 photo-2 photo-2"
        "photo-1 photo-1 photo-1 photo-1 photo-2 photo-2"
        "photo-1 photo-1 photo-1 photo-1 photo-2 photo-2"
        "photo-1 photo-1 photo-1 photo-1 photo-3 photo-3"
        "photo-1 photo-1 photo-1 photo-1 photo-3 photo-3"
        "photo-1 photo-1 photo-1 photo-1 photo-3 photo-3";
}
.facebook-grid-4 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 2.5px;
    background-color: #ffff !important;
    grid-template-areas:
        "photo-1 photo-1 photo-1 photo-1 photo-2 photo-2"
        "photo-1 photo-1 photo-1 photo-1 photo-2 photo-2"
        "photo-1 photo-1 photo-1 photo-1 photo-3 photo-3"
        "photo-1 photo-1 photo-1 photo-1 photo-3 photo-3"
        "photo-1 photo-1 photo-1 photo-1 photo-4 photo-4"
        "photo-1 photo-1 photo-1 photo-1 photo-4 photo-4";
}
.facebook-grid-5 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 2.5px;
    background-color: #ffff !important;
    grid-template-areas:
        "photo-1 photo-1 photo-1 photo-2 photo-2 photo-2"
        "photo-1 photo-1 photo-1 photo-2 photo-2 photo-2"
        "photo-1 photo-1 photo-1 photo-2 photo-2 photo-2"
        "photo-3 photo-3 photo-4 photo-4 photo-5 photo-5"
        "photo-3 photo-3 photo-4 photo-4 photo-5 photo-5";
}

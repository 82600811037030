.aui-checkbox-select {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #eaf0f6;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 200ms ease-in-out;
    /* transition: background-color 2ms ease-in-out; */
}

.aui-checkbox-selected {
    background-color: rgb(3, 143, 3);
    border: 1px solid rgb(3, 143, 3);
}


.aui-checkbox-selected svg {
    fill: #fff;
    font-size: 16px;
}

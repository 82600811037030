.aui-spin-loader {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    animation: spin 2s ease-in infinite;
    border-radius: 50%;
    color: rgb(128, 128, 128);
    z-index: 10000;
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.aui-line-skeleton-loader,
.aui-circle-skeleton-loader {
    animation: fade 1.5s ease-in-out 0.5s infinite;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10000;
}

.aui-container-skeleton-loader {
    z-index: 10000;
    background-color: rgb(231, 231, 231);
}

.aui-line-skeleton-loader {
    border-radius: 6px;
    margin: 0.5rem 0.2rem;
}

.aui-circle-skeleton-loader {
    border-radius: 50%;
    margin: 0.5rem 0.2rem;
}

.aui-container-skeleton-loader {
    border-radius: 6px;
    padding: 1rem 1.5rem;
    margin: 1rem 0.2rem;
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

.help-support-container {
    padding: 0px 13px;
    margin-bottom: 20px;

    > div {
        border: 0.1px solid #425b7633;
        border-radius: 6px;
        box-shadow: 1px 1px 6px #425b7633;
        padding: 20px;
        width: calc(96%);

        > h6 {
            font-size: 22px;
            font-weight: 600;
            color: #33475b;
        }

        > .supports {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 20px;

            @media (max-width:480px) {
                flex-wrap: wrap;
                justify-content: space-around;
            }

            > a {
                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;
    
                    > .icon {
                        padding: 12px;
                        width: 84px;
                        height: 84px;
                        border-radius: 12px;
                        // background-color: #7b68ee;
    
                        > img {
                            width: 100%;
                            height: auto;
                        }
                    }
    
                    > p {
                        font-size: 14px;
                        color: #33475b;
                        margin-top: 12px;
                    }
                }
            }
        }
    }
}

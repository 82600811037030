.avatar {
    cursor: pointer;
    display: flex;
    overflow: hidden;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    align-items: center;
    color: #fff;
    line-height: 145%;
    font-size: 1.1rem;
    width: 30px;
    height: 30px;

    &-user {
        width: 100%;
        height: 100%;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.square {
    border-radius: 0;
}

.aui-media-selection-dialog {
    width: 95%;
    height: 95%;
    max-width: 720px;
    max-height: 850px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 6px;
    z-index: 100000000000006;

    &-backdrop {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1000000005;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: saturate(180%) blur(2px);
    }

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1.5rem;
        font-size: 1.2rem;
        font-weight: 500;
    }

    &-cross {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 2rem;
        cursor: pointer;
        color: #33475b;
        transition: all 0.3s ease;
        transform: rotateZ(45deg);
        background: #dde5ec;

        &:hover {
            background-color: #33475b;
            color: #fff;
        }
    }

    &-tab-content {
        height: calc(100% - 112px);
        max-height: calc(100% - 112px);
        position: relative;
    }

    .final-checkout-model {
        border-radius: 6px;
        z-index: 10000000000;
        background-color: #fff;
        position: absolute;
        left: 50%;
        transform-origin: center;
        bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        padding: 8px 12px;
        transform: translate(-50%, 0%) scale(0);
        transition: transform 400ms ease-in-out;
        box-shadow: 0 1px 4px #9c9c9c59;
    }

    .final-checkout-model-show {
        transform: translate(-50%, 0%) scale(1);
    }
}

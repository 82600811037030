.error-page {
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .error-main {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        > h1 {
            margin: 2rem 0;
            margin-top: 2.2rem;
            font-size: 2.8rem;
        }
        > p {
            margin: 1.8rem 0;
            color: #7c98b6;
        }
        .error-icon {
            height: 400px;
            object-fit: contain;
        }
        .error-btn-wrapper {
            .error-home {
                width: 200px;
                margin: 1rem auto;
                display: block;
                padding: 1.4rem 3rem;
                background-color: #0299ff;
                color: #ffffff;
                text-decoration: none;
            }
            .error-log {
                color: #7f7dda;
                text-decoration: underline;
                cursor: pointer;
                text-align: center;
            }
        }
        .error-button{
            width: 100%;
            

            .reload {
                width: 10rem;
                height: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #1da1f2;
                color: white;
                font-weight: 500;
                font-size: 1rem;
                border-radius: 30px;
                margin: 0 auto;
                cursor: pointer;

                &:hover {
                    box-shadow: 0px 0px 6px #1da1f2;
                }
            }
        }
    }
}

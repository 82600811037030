@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

// TODO: We Need to make this dynamic
@import url("https://fonts.googleapis.com/css?family=Montserrat'");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova&family=Merriweather&family=Taviraj&family=Uchen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Capriola&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&display=swap');
@import "./mixin";

.App {
    text-align: center;
    width: 100%;
    position: relative;
    height: 100%;
}

* {
    box-sizing: border-box !important;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

body {
    font-size: 85%;

    @media (max-width: 678px) {
        font-size: 75%;
    }
    @media (max-width: 567px) {
        font-size: 66%;
    }
}

.picker-dialog-bg {
    z-index: 100000000000000 !important;
}

.picker-dialog {
    z-index: 1000000000000111 !important;
}

.scroll-modern {
    overflow-y: auto;
    @include scroll;
}

.scroll-modern{
    overflow-y: auto;
    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
        border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #D6DFE8;
        border-radius: 20px;
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    > img {
        width: auto;
        height: 100%;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

.social-icon-background {
    background-color: white;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

#post-management-title {
    display: flex;
    gap: 1rem;
    align-items: center;

    .icon {
        width: 2.75rem;
        height: 2.75rem;
        padding: 0.25rem;
        border-radius: 6px;

        img {
            width: 100%;
            height: auto;
            max-height: 1.25rem;
        }
    }
    .head {
        > div {
            font-size: 0.75rem;
            font-weight: 300;
            color: #99acc2;
            text-align: left;

            &:first-child {
                font-size: 1.1rem;
                color: #2e3f50;
                font-weight: 600;
            }
        }
        border-radius: 4px;
        min-height: 3rem;
    }
}

svg {
    height: 100%;
}

.postCreator {
    position: relative;

    > .image {
        width: 2rem;
        height: 2rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        > img {
            width: auto;
            height: 100%;
        }

        > .tooltip {
            padding: 0.5rem 1rem !important;
            left: 50%;
            margin-left: 0;
            top: 120%;
            transform: translateX(-50%);
        }

        &:hover {
            > .tooltip {
                visibility: visible;
            }
        }
    }
}

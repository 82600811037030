.aui-post-collaboration-comment-strip {
    display: flex;
    gap: 1rem;
    min-height: 56px;
    border: 1px solid transparent;
    border-bottom: 1px solid #dbe3e9a1;
    padding: 0.5rem 2rem;
    width: 100%;
    animation: fade-in 0.3s ease-in 1;
    background-color: white;

    &-approved {
        background-color: #ccfbe245;
        border-top: 1px solid #25c16f;
        border-bottom: 1px solid #25c16f;
    }
    &-rejected {
        background-color: #ffe8e97d;
        border-top: 1px solid #f2545b;
        border-bottom: 1px solid #f2545b;
    }

    &-top {
        display: flex;
        gap: 1.5rem;
        align-items: center;
    }

    &-name {
        color: #33475b;
        font-size: 16px;
        font-weight: bold;
    }
    &-comment {
        text-align: left;
        line-break: anywhere;
        white-space: pre-line;
        color: #33475b;
    }
    &-bottom {
        text-align: left;
        color: #99acc2;
        margin-top: 0.25rem;
    }

    &-status {
        &-approved {
            > span {
                color: #25c16f;
            }
        }
        &-rejected {
            > span {
                color: #f2545b;
                &:nth-child(2) {
                    font-weight: 500;
                }
            }
        }
    }
}

.aui-comment-image {
    margin: 24px auto 12px auto;
    border-radius: 10px;
    width: 100%;
    height: 285px;
    overflow: hidden;
    background-color: #ffffff;
    display: grid;
    box-shadow: 0 -3px 6px rgba(205, 215, 227, 0.568627);

    div {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #00000066;
            > p {
                color: #ffffff;
                font-size: 30px;
                font-weight: 500;
                line-height: 145%;
            }
        }
    }

    div img {
        // transform: translateY(-25%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .photo-1 {
        grid-area: photo-1;
    }
    .photo-2 {
        grid-area: photo-2;
    }
    .photo-3 {
        grid-area: photo-3;
    }
    .photo-4 {
        grid-area: photo-4;
    }
}
.twitter-grid-2 {
    grid-template-columns: 1fr 1fr;
    gap: 1px;
    grid-template-areas: "photo-1 photo-2";
}
.twitter-grid-3 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1px;
    grid-template-areas: "photo-1 photo-2" "photo-3 photo-3";
}
.twitter-grid-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1px;
    grid-template-areas: "photo-1 photo-2" "photo-3 photo-4";
}

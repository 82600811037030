.social-icon-inactive {
    width: 100%;
    height: 100%;
    display: flex;

    svg {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }

    path {
        fill: #d6dae2;
    }
    circle {
        fill: #d6dae2;
    }
    .twitter-icon-logo {
        fill: #fff;
    }
    #Group_User {
        circle {
            fill: white;
        }

        path {
            fill: white;
        }

        g {
            fill: white;
        }
    }

    #linkedin-company-page {
        path {
            fill: white;
        }
    }
}
.social-icon-active {
    width: 100%;
    height: 100%;
    display: flex;

    svg {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }
}

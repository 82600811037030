.facebookFeedPost {
    display: flex;
    flex-direction: column;
    margin: 12px auto;
    max-width: 500px;
    width: 90%;
    position: relative;
    border-radius: 7px;
    border: 1px solid #D6DFE8;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(200, 200, 200, 0.8);
    cursor: pointer;

    > .postTop {
        padding: 1rem 0.75rem 0px 0.75rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        // margin-bottom: 12px;

        > .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            // background-color: #092f42;
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
                border-radius: 50%;
                height: 100%;
                width: auto;
            }
        }

        > .title {
            margin-left: 0.6rem;
            text-align: left;

            > .name {
                font-size: 1rem;
                color: #092f42;
                line-height: 19px;
                font-weight: 500;
            }
            > .time {
                font-size: 0.8rem;
                color: #666666;
                line-height: 19px;
            }
        }
    }

    > .text {
        padding: 12px 12px 0 12px;
        text-align: left;
        font-size: 13px;
        color: #050505;
        font-weight: 300;

        a {
            text-decoration: none;
            color: #00aeef;

            &:hover {
                text-decoration: underline;
                opacity: 0.8;
            }
        }
    }
    
    >.postText {
        white-space: pre-line;
    }

    > .readMore {
        color: #99acc2;
        padding: 0 12px 12px 12px;
        text-align: left;
        font-weight: 500;
    }

    .thumbnail-wrapper {
        padding: 1;
    }

    > .stats {
        border-top: 1px solid #D6DFE8;
        border-bottom: 1px solid #D6DFE8;
        margin: 0.25rem;
        margin-top: 12px;
        margin-bottom: 12px;
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .comment,
        .hearts,
        .share {
            display: flex;
            cursor: pointer;
            align-items: center;
            > svg {
                height: 25px;
                width: 25px;
                margin-right: 12px;
                fill: rgb(35, 38, 41);
                > .f-l {
                    stroke: rgb(35, 38, 41);
                }
                > .f-2 {
                    stroke: rgb(35, 38, 41);
                }
            }
        }

        > div {
            margin-right: 1rem;
        }
    }
}

.changeColor{
    color: rgb(0, 172, 238);
}

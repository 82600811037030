body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
    color: #33475b;
}

.orange-bg {
    background-color: #ff5745;
}

.orange-clr {
    color: #ff5745;
}
.green-bg {
    background-color: #ecf2f6;
}

.green-clr {
    color: #ecf2f6;
}

.icon {
    > img {
        width: 100%;
        height: 100%;
    }
}

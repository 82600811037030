.twitterPreviewPost {
    max-width: 500px;
    min-width: 250px;
    margin: 1rem auto;
    position: relative;
    position: relative;
    background-color: white;
    padding: 10px;
    display: flex;
    border: 1px solid #D6DFE8;
    border-radius: 17px;
    cursor: pointer;

    .status {
        width: 24px;
        height: 24px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 5px;
        right: 5px;
        cursor: pointer;
        > .image {
            width: 100%;
            height: 100%;
        }
    }

    > .twitter-right {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;

        > .title {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;

            > .icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                // background-color: #092f42;
                display: flex;
                align-items: center;
                justify-content: center;
    
                > img {
                    border-radius: 50%;
                    height: 100%;
                    width: auto;
                }
            }
        }

        > .text {
            text-align: left;
            font-size: 0.9rem;
            color: #0f1419;
            font-weight: 400;

            a {
                text-decoration: none;
                color: #00aeef;

                &:hover {
                    text-decoration: underline;
                    opacity: 0.8;
                }
            }
        }

        >.postText {
            white-space: pre-line;
        }

        > .readMore {
            color: #99acc2;
            padding: 0 12px 12px 0;
            text-align: left;
            font-weight: 500;
        }

        > .image {
            margin: 12px auto;
            border-radius: 10px;
            width: 100%;
            height: 285px;
            overflow: hidden;
            background-color: #ffffff;
            display: grid;

            div {
                height: 100%;
                width: 100%;
                overflow: hidden;
                position: relative;

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background-color: #00000066;
                    > p {
                        color: #ffffff;
                        font-size: 30px;
                        font-weight: 500;
                        line-height: 145%;
                    }
                }
            }

            div img {
                // transform: translateY(-25%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .photo-1 {
                grid-area: photo-1;
            }
            .photo-2 {
                grid-area: photo-2;
            }
            .photo-3 {
                grid-area: photo-3;
            }
            .photo-4 {
                grid-area: photo-4;
            }
        }
        .grid-2 {
            grid-template-columns: 1fr 1fr;
            gap: 1px;
            grid-template-areas: "photo-1 photo-2";
        }
        .grid-3 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 1px;
            grid-template-areas: "photo-1 photo-2" "photo-3 photo-3";
        }
        .grid-4 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 1px;
            grid-template-areas: "photo-1 photo-2" "photo-3 photo-4";
        }

        > .timestamp {
            margin: 0.25rem auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .iButton {
                width: 1rem;
                height: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: 1px solid #666;
            }
        }

        > .hr {
            width: 100%;
            height: 1px;
            margin: 0.4rem auto;
            background-color: rgb(204, 214, 221);
        }

        > .stats {
            width: 100%;
            margin: 0.25rem;
            margin-top: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .comment,
            .hearts,
            .retweet {
                display: flex;
                cursor: pointer;
                align-items: center;
                > svg {
                    height: 25px;
                    width: 25px;
                    fill: rgb(35, 38, 41);
                    .t-c {
                        fill: rgb(35, 38, 41);
                    }
                    .t-l {
                        fill: rgb(35, 38, 41);
                    }
                }
            }

            > div {
                margin-right: 1rem;
            }
        }
    }

    &:hover {
        background-color: rgb(245, 248, 250);
    }
}

.changeColor{
    color: rgb(0, 172, 238);
}

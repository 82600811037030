@mixin scroll {
    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
        border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #D6DFE8;
        border-radius: 20px;
    }
}

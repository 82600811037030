.upload-files-wrapper {
    width: 100%;
    height: 100%;
    padding: 12px;
    background-color: #f9fafc;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    .file-uploader {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .file-uploader-top {
            width: 100%;
            height: 70%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            > div {
                color: #a5b6c9;
                margin-top: 8px;
            }
        }

        .aui-file-uploader {
            margin: auto;
        }

        .loaderWrapper {
            margin: auto;
        }

        .other-file-picking-methods {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            .media-icon-wrapper{
                text-align: center;
            }

            .media-icon {
                width: 9rem;
                min-height: 3rem;
                margin: 0 12px;
                border: 1px solid #b9d3ec;
                background-color: #ebf1f8;
                padding: 6px;
                border-radius: 4px;
                gap: 0.25rem;
                display: flex;
                justify-content: center;
                align-items: center;

                > div {
                    gap: 0.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    width: 23px;
                }
                > svg {
                    width: 23px;
                    margin-right: 12px;
                }
            }
        }
    }
}
.hover {
    background-color: #dee4eb;
}

.linkedInPost {
    position: relative;
    margin: 12px auto;
    max-width: 500px;
    width: 90%;
    border-radius: 7px;
    background-color: white;
    border: 1px solid #D6DFE8;
    box-shadow: 0px 0px 4px rgba(200, 200, 200, 0.8);
    cursor: pointer;
    display: flex;
    flex-direction: column;

    > .postTop {
        margin-top: 12px;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // margin-bottom: 12px;

        > .icon {
            width: 40px;
            height: 40px;
            display: flex;
            margin-right: 6px;
            align-items: center;
            justify-content: center;

            > img {
                border-radius: 2px;
                height: 100%;
                width: auto;
            }
        }

        > .title {
            margin-left: 0.6rem;
            text-align: left;

            > .name {
                font-size: 0.98rem;
                color: #092f42;
                line-height: 19px;
                font-weight: 500;
            }
            > .time {
                font-size: 0.8rem;
            }
        }
    }

    > .text {
        padding: 12px 12px 0 12px;
        text-align: left;
        font-size: 13px;
        color: #050505;
        font-weight: 300;
        span {
            font-weight: 600;
            margin-right: 5px;
        }

        a {
            text-decoration: none;
            color: #00aeef;

            &:hover {
                text-decoration: underline;
                opacity: 0.8;
            }
        }
    }

    >.postText {
        white-space: pre-line;
    }

    > .readMore {
        color: #99acc2;
        padding: 0 12px 12px 12px;
        text-align: left;
        font-weight: 500;
    }

    > .stats {
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 12px;
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba(0, 0, 0, 0.08);

        > div {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            //   cursor: pointer;
            line-height: 145%;
            font-weight: 400;
            > svg {
                width: 25px;
                height: 25px;
                margin-right: 6px;
                fill: rgb(35, 38, 41);
                path {
                    max-width: 25px;
                    max-height: 25px;
                }
            }
        }

        > div {
            margin-right: 1rem;
        }
    }
}

.changeColor{
    color: rgb(0, 172, 238);
    font-weight: 400 !important;
}
